 import appConfig from  './apiConfig';

 //Api For Updating Participant
 export const updateParticipantApi = (data) => {
	//let participantData = JSON.stringify(data);
	const headers = Object.assign({
		'Content-Type': 'application/json',
	});
	const request = new Request(appConfig.siteURL + 'participantsUpdate/' + data.uid +"/"+ data.checkLoi +"/" + data.sid + "/" + data.status, {
		method: "GET",
		headers: headers,
		//body: participantData
	});

	return fetch(request).then(response => {
		return response.json().then(result => {
			return result;
		});
	}).catch(error => {
		console.log("update participant", error)
		return error;
	});
}