const initialState = {
  updateParticipantResp : ""
  }
  
  const successStore = (state = initialState , action) =>{
    switch (action.type) {

          case "SET_UPDATE_PARTICIPANT_RESPONSE":
            return {
              ...state,
              updateParticipantResp : action.updateParticipantResp
            }
        default:
            return state;
          }
  
  };
  export default successStore;
  