
import {updateParticipantApi} from '../api/overQuotaApi'; 

export const updateParticipant = (data) => {
	return dispatch => {

		updateParticipantApi(data).then((result) => {

			dispatch(setUpdateParticipant(result));
			if(result.message){
				alert(result.message);
			}	
		});
	}
}

export const setUpdateParticipant = (updateParticipantResp) => {
	return {
		type: "SET_UPDATE_PARTICIPANT_RESPONSE",
		updateParticipantResp

	}
}